<template>
  <div class="detail">
    <div class="banner-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <h1 v-if="content">{{ content.title }}</h1>
            <div v-else class="loading">Loading...</div>
            <!-- <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit.
              Voluptatibus non aliquam repellendus id consectetur facilis
              voluptatum, quasi, nostrum rerum nemo dolores illo.
            </p> -->
          </div>
        </div>
      </div>
    </div>
    <div
      class="container text-content"
      v-if="content"
      v-html="content.content"
    ></div>
  </div>
</template>

<script>
export default {
  name: "ContentPage",
  components: {},
  props: ["content"],
};
</script>

<style lang="scss">
@import "@/style/content-page.scss";
</style>
