<template>
  <div class="about">
    <ContentPage :content="content" />
  </div>
</template>

<script>
import ContentPage from "@/components/ContentPage.vue";
import CommonService from "@/services/CommonService";

export default {
  name: "terms-and-conditions",
  components: { ContentPage },
  data() {
    return {
      content: null,
    };
  },
  created() {
    this.fetchContent();
  },
  methods: {
    fetchContent() {
      let params = {
        slug: "terms-and-conditions",
      };
      CommonService.getContentPage(params)
        .then((response) => {
          // console.log(response.data);
          if (response.data.status === "SUCCESS") {
            this.content = response.data.content;
          }
          if (response.data.status === "ERROR") {
            this.$toast.error(response.data.message);
          }
          if (response.data.status === "VALIDATION_FAILED") {
            // console.log(response.data.errors);
            let errorsObject = response.data.errors;
            // Note:- Object.values gives the values as an array
            let errorValuesArray = Object.values(errorsObject);

            if (errorValuesArray.length > 0) {
              for (let index = 0; index < errorValuesArray.length; index++) {
                this.$toast.error(errorValuesArray[index]);
                // console.log(errorValuesArray[index]);
              }
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
